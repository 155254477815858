import React, { useState, useEffect } from "react";
import "../App.css";
// import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
function Downtime() {
  const [isLLMDown, setIsLLMDown] = useState(false);
  const [startTime, setStartTime] = useState("N/A");
  const [endTime, setEndTime] = useState("N/A"); 
  const  [startTimeAMPM,setStartTimeAMPM] = useState("");
  const  [endTimeAMPM,setEndTimeAMPM] = useState("")

  // Determine if the screen width is mobile
  // const isMobile = window.innerWidth <= 768;

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":").map(Number);
    const ampm = hour >= 12 ? "PM" : "AM";
    const adjustedHour = hour % 12 || 12; // Convert to 12-hour format
    return `${String(adjustedHour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL1}/downtime`);
        const data = await response.json();
        console.log(data);

        setIsLLMDown(data.isLLMDown);

        // Convert times to 12-hour format
        const formattedStartTime = convertTo12HourFormat(data.startTime || "N/A");
        const formattedEndTime = convertTo12HourFormat(data.endTime || "N/A");

        setStartTime(formattedStartTime);
        setEndTime(formattedEndTime);
        setStartTimeAMPM(data.startTimeAMPM || ""); // Get AM/PM
        setEndTimeAMPM(data.endTimeAMPM || ""); // Get AM/PM

      } catch (error) {
        console.error("Error fetching data from API", error);
        setIsLLMDown(false);
      }
    };

    fetchData();
  }, []);


  return (
    
    <div className="dcontainer" >
    <div className="dcard">
      <img
        className="icon"
        src="/downtime.png"
        alt="Logo"
      />
      <h1  className="downttime-text">Application is under maintance</h1>
    </div>
    <div className="text">
      <p  className="downttime-text">
      We are performing routine maintenance from <b>{startTime}{startTimeAMPM}- {endTime} {endTimeAMPM}</b>
      </p>
    </div>
    <div className="btn">
      {/* <button className="retry-button" >
        Retry Now
      </button> */}
    </div>
  </div>
  );
}

export default Downtime;