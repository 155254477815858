export const attach_icon = "attach_icon";
export const submit_image = "submit_image";
export const SendButton = "send button";
export const type_doubts = "type_doubts";
export const click_photo = "click_photo";
export const cancel_attach = "cancel_attach";
export const cancel_image = "cancel_image";
export const Suggestion_1st = "1st_suggestion";
export const Suggestion_2nd = "2nd_suggestion";
export const other_Suggestion = "other_suggestion";
export const yes_feedback = "yes_feedback";
export const no_feedback = "no_feedback";
export const ask_another_doubt = "ask_another_doubt";
export const explain_step = "explain_step";
export const followup_question = "followup_question";
export const upload_document = "upload_document";
