import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';

import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '380px',
    
    bgcolor: '#fff', // White background for the modal box
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    borderRadius: '12px',
    p: 4,
    textAlign: 'center',
    background: 'linear-gradient(135deg, #FFD3A5, #FFAAA6)', // Peach to pink gradient background
    position: 'relative'
};

export default function FreeFinish({showModal, setShowModal, FreeDoubt, codeSnippet, freeFinishProps}) {
    const handleClose = () => setShowModal(false); 
 
    const checkoutHandler = async () => { 
    let membershipPlanId="66b1dfce9f00124893ab9da8";
    const response  = await axios.post(`${process.env.REACT_APP_API_URL}/membership/checkout`, {
        studentId:freeFinishProps.studentId,
        apiKey:freeFinishProps.ApiKey,
        membershipPlanId
    })
     
    const order = response.data;
    const options = {
        key: order.razorpay_key_id,
        amount: order.amount,
        currency: "INR",
        name: order.name,
        description: "Membership Plans",
        image: "https://uat.chatbot.doubtbuddy.com/logoFrame.png",
        order_id: order.id,
        handler: async function (response) { 
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/membership/payment/verify`, {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature
                });
            } catch (error) {
                console.error("Payment verification failed:", error);
            }
        },
        prefill: {
            name: order.name,
            email: order.email,
            contact: order.mobile
        },
        notes: {
            address: "Razorpay Corporate Office"
        },
        theme: {
            color: "#121212"
        }
    };

    const razor = new window.Razorpay(options);
    razor.open();
}

function BuyNow() {
    let func = new Function(codeSnippet); 
    if(codeSnippet == null || codeSnippet.length == 0){
        checkoutHandler()
    }else{
      func();
    }
}
    
    return (
        <div className='free-doubt-main'>
            <Modal
                open={showModal}
                onClose={handleClose}
            >
                <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        color: '#333'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                    <img style={{width:'50px',height:'50px',borderRadius:'50%'}} src='/download.jpeg' />
                    <div className='free-heading'
                    style={{
                        fontWeight: 'bold', 
                        fontSize: '15px',
                        marginBottom: '10px',
                        color: '#333'
                    }}
                >
                    Unlock Unlimited Doubt Solving!
                </div>

                {/* Dynamic text */}
                <div className='free-text1'
                    style={{
                        fontSize: '12px', 
                        color: '#666', 
                        marginBottom: '15px',
                        padding: '0 20px' // Padding for better text spacing
                    }}
                >
                    Your free limit of {FreeDoubt} doubts is up. Upgrade to our Motion Doubt Solver for endless answers!
                </div>
                    <div className='free-btn-container'>
                        <Button variant="contained" onClick={BuyNow}
                         style={{
                            backgroundColor: '#b4192e',
                            // color: buttonTextColor,
                            width:'170px',
                            margin:'auto',
                            fontWeight:'bold'
                            
                        }}
                        
                        >Buy Now</Button>
                       
                    </div>

                </Box>
            </Modal>
        </div>
    );
}
