import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '328px',
    maxHeight:'415px',
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

export default function FreeFinish({showModal, setShowModal, FreeDoubt, codeSnippet, freeFinishProps}) {
    const handleClose = () => setShowModal(false); 
    let membershipPlanId="66b1dfce9f00124893ab9da8";
 
  const checkoutHandler = async () => { 
    let membershipPlanId="66b1dfce9f00124893ab9da8";
    const response  = await axios.post(`${process.env.REACT_APP_API_URL}/membership/checkout`, {
        studentId:freeFinishProps.studentId,
        apiKey:freeFinishProps.ApiKey,
        membershipPlanId
    })
     
    const order = response.data;
    const options = {
        key: order.razorpay_key_id,
        amount: order.amount,
        currency: "INR",
        name: order.name,
        description: "Membership Plans",
        image: "https://uat.chatbot.doubtbuddy.com/logoFrame.png",
        order_id: order.id,
        handler: async function (response) { 
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/membership/payment/verify`, {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature
                });
            } catch (error) {
                console.error("Payment verification failed:", error);
            }
        },
        prefill: {
            name: order.name,
            email: order.email,
            contact: order.mobile
        },
        notes: {
            address: "Razorpay Corporate Office"
        },
        theme: {
            color: "#121212"
        }
    };

    const razor = new window.Razorpay(options);
    razor.open();
}

function BuyNow() {
    let func = new Function(codeSnippet); 
    if(codeSnippet == null || codeSnippet.length == 0){
        checkoutHandler()
    }else{
      func();
    }
}
     
    return (
        <div className='free-doubt-main'>
            <Modal
                open={showModal}
            >
                <Box sx={style}>
                    <img src='/free-finish.png' />
                    <div className='free-heading'>
                        Your trial has ended !
                    </div>

                    <div className='free-text1'>{`You have exceed your free limit of ${FreeDoubt} doubts`}</div>
                    <div className='free-text2'> Still have doubts ?</div>
                    <div className='free-text2'>Purchase this feature to continue using</div>

                    <div className='free-btn-container'>
                        <Button variant="contained" onClick={BuyNow}
                         
                        >Buy Now</Button>
                        <Button variant="text" sx={{ mt: 1 }} onClick={handleClose}>Go Back</Button>
                    </div>

                </Box>
            </Modal>
        </div>
    );
}
