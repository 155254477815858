import mixpanel from "mixpanel-browser";
const MIXPANEL_TOKEN = process.env.REACT_APP_MIX_PANEL_TOKEN

let obj = {};

export const setMixPanelDefaultProperties = (d) => {  
    obj= d;
}

export const initMixpanel = () => { 
  mixpanel.init(MIXPANEL_TOKEN, { debug: true });
};

export const trackEvent = (eventName, properties) => {
  if(properties)  {
    for(var prop in properties){
        obj[prop] = properties[prop]; 
    }
  }
  
  mixpanel.track(eventName, obj);
};